.staff-container {
  background-color: #141414;
  color: white;
  padding: 20px;
  padding-bottom: 10px;
  text-align: center;
}

.staff-title {
  font-size: 36px;
  font-weight: 700;
  margin-bottom: 40px;
}

.staff-content {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin: 0 auto;
  width: fit-content;
}

.staff-section {
  text-align: left;
  display: flex;
  align-items: baseline;
}

.staff-heading {
  font-size: 18px;
  margin-bottom: 10px;
  font-weight: bold;
  display: inline-block;
  width: 50px;
}

.staff-names {
  display: inline-block;
  font-size: 18px;
  font-weight: 600;
  line-height: 1.5;
  margin-left: 10px;
  margin-bottom: 10px;
}

.staff-name {
  display: inline;
  margin-right: 8px;
}

@media (max-width: 400px) {
  .staff-container h2 {
    font-size: 24px;
  }
  .staff-section div {
    font-size: 16px;
  }
}
