@font-face {
  font-family: "Pretendard-Regular";
  src: url("https://fastly.jsdelivr.net/gh/Project-Noonnu/noonfonts_2107@1.1/Pretendard-Regular.woff")
    format("woff");
  font-weight: 400;
  font-style: normal;
}

* {
  font-family: "Pretendard-Regular", sans-serif;
  box-sizing: border-box;
}

body {
  margin: 0;
}

.apply-container {
  background-color: #141414;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  /* width: 100%; */
  align-items: center;
  /* justify-content: center; */
  /* padding: 0 20px; */
  align-items: flex-start;
  color: #fff;
  padding-top: 100px;
  padding-top: 40px;
  /* margin-bottom: 20px; */
}

.apply-container h1:first-child {
  margin-top: 100px;
}

form {
  display: flex;
  flex-direction: column;
  align-items: start;
  width: 80%;
  max-width: 600px;
  margin-left: 20px;
  margin-top: 20px;
}

.custom-file-upload {
  width: 100%;
}

.custom-file-upload input[type="file"] {
  display: none;
}

form input::placeholder,
form select::placeholder {
  color: #d9d9d9;
}

span .span-portfolio {
  color: #d9d9d9;
  font-weight: lighter;
}

form label {
  display: block;
  margin-bottom: 0.5em;
  font-weight: bold;
}

button {
  background-color: #141414;
  color: #fff;
  padding: 0.55em;
  border: solid;
  text-decoration: underline;
  font-size: 1em;
  cursor: pointer;
  margin-bottom: 0.5em;
  transition: background-color 0.3s ease;
}

button:hover {
  background-color: #333;
}

.apply-container h1 {
  display: block;
  margin-left: 20px;
  font-size: 80px;
  font-weight: bold;
  margin-block-start: 0em;
  margin-block-end: 0em;
  color: #fff;
  margin-left: 40px;
}

form {
  text-align: left;
}

.apply-container div {
  /* margin: 20px; */
  font-size: 24px;
  font-weight: 600;
  /* margin-right: 40px; */
  display: flex;
  /* align-content: center; */
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;
  margin-top: 20px;
}

.apply-container div .gradu {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 20px;
}

div .apply-container p {
  color: #141414;
  font-weight: bold;
  text-decoration: underline;
  display: flex;
  flex-direction: column;
  margin: 20px;
  /* align-items: center;*/
}

.apply {
  margin-left: 40px;
  height: 98px;
  /* font-size: 36px; */
  /* width: 360px; */
}

.link-apply {
  /* margin-left: 20px; */
  height: 98px;
  width: 360px;
}

.link-apply a {
  display: block;
  text-align: center;
  height: 100%;
  line-height: 98px;
  width: 100%;
  color: #000;
  text-decoration: underline;
  font-size: 36px;
  font-weight: 700;
  background: #fff;
}

@media (max-width: 850px) {
  .apply-container h1 {
    font-size: 60px;
    font-weight: bold;
  }
  .apply-container div {
    /* margin: 20px; */
    font-size: 20px;
    font-weight: 600;
  }
  .apply-container div .gradu {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    margin-top: 0px;
  }
}

@media (max-width: 810px) {
  .link-apply {
    margin-left: 0;
    margin-bottom: 10px;
  }
  .apply-container h1 {
    margin-left: 40px;
  }
  .apply {
    margin-left: 40px;
    height: 98px;
  }
}

@media (max-width: 767px) {
  .apply-container h1 {
    font-size: 40px;
    font-weight: bold;
  }
  .apply-container div {
    /* margin: 20px; */
    font-size: 18px;
    font-weight: 600;
  }
  .apply-container {
    /* padding: 0 20px; */
    padding-top: 100px;
  }
}

@media (max-width: 540px) {
  .link-apply a {
    font-size: 20px;
    line-height: 62.33px;
    width: 200px;
  }
  .link-apply {
    height: 62.33px;
    margin-top: 10px;
    width: 100%;
    margin-left: 0;
    margin-bottom: 20px;
    max-width: 350px;
  }
  .apply {
    margin-left: 40px;
    height: 98px;
  }
  .apply div .link-apply {
    margin-left: 40px;
  }
  .apply-container {
    /* padding: 0 20px; */
    padding-top: 100px;
  }
  .apply div .gradu a {
    /* margin-left: 20px; */
    /* padding: 0 20px; */
  }
}

@media (min-width: 851px) {
    .link-apply:last-child {
        margin-left: 40px;
    }
}