@font-face {
  font-family: "Pretendard-Regular";
  src: url("https://fastly.jsdelivr.net/gh/Project-Noonnu/noonfonts_2107@1.1/Pretendard-Regular.woff")
    format("woff");
  font-weight: 400;
  font-style: normal;
}

.content {
  background-color: #141414;
  font-family: "Pretendard-Regular", sans-serif;
  padding-bottom: 5px;
  padding-top: 20px;
}

.content h1 {
  display: block;
  margin-left: 40px;
  /* font-size: 5em; */
  font-size: 80px;
  margin-block-start: 0em;
  margin-block-end: 0em;
  font-weight: 700;
  color: #fff;
  font-weight: 700;
}
.content h1:first-child {
  margin-top: 100px;
}

div .title {
  padding-top: 40px;
  margin-bottom: 20px;
}

.main-box {
  display: flex;
  align-items: flex-start;
  margin-left: 40px;
  padding-bottom: 60px;
}

.button-container {
  width: 360px;
  height: 98px;
  margin-bottom: 20px;
  position: relative;
  text-align: left;
}

.button-container img {
  min-width: 200px;
  height: auto;
}
.countdown {
  width: 360px;
  height: 98px;
  position: relative;
}
.countdown img {
  display: block;
  position: absolute;
  left: 0;
}
.countdowntext {
  width: 100%;
  height: 100%;
  text-align: center;
  position: absolute;
  left: 0;
  z-index: 10;
  color: #fff;
}
.countdowntext div:first-child {
  margin-top: 2px;
  font-size: 50px;
}
.countdowntext div:nth-child(2) {
  font-size: 20px;
}
.countdowntext {
  padding: 0;
}

.link-form {
  margin-left: 20px;
  height: 98px;
  width: 360px;
}

.link-form a {
  display: block;
  text-align: center;
  height: 100%;
  line-height: 98px;
  width: 100%;
  color: #000;
  text-decoration: underline;
  font-size: 36px;
  font-weight: 700;
  background: #fff;
}

.white_arrow_down {
  display: block;
  margin: 50px auto;
  margin-bottom: 50px;
  animation: arrowfloating 1s alternate-reverse infinite ease-in-out;
}
@keyframes arrowfloating {
  0% {
    transform: translateY(-10px);
  }
  100% {
    transform: translateY(10px);
  }
}

@media (max-width: 810px) {
  .content h1 {
    font-size: 48px;
  }
  .content h1:first-child {
    margin-top: 60px;
  }

  .link-form {
    margin-left: 0;
    margin-bottom: 10px;
  }
  .main-box {
    flex-direction: column;
    margin-left: 40px;
    padding-right: 40px;
  }
  .countdown {
    margin-top: 10px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
  }
}

@media (max-width: 540px) {
  .content h1 {
    font-size: 3em;
  }

  .main-box {
    flex-direction: column;
    margin-left: 40px;
    padding-right: 40px;
    padding-bottom: 60px;
  }
  .button-container {
    width: 100%;
    height: fit-content;
  }
  .link-form a {
    font-size: 30px;
    line-height: 62.33px;
  }
  .countdown {
    height: 63.33px;
    margin-top: 10px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    max-width: 350px;
  }
  .countdowntext {
    height: fit-content;
  }
  .countdowntext div:first-child {
    font-size: 40px;
    margin-top: 0px;
  }
  .countdowntext div:nth-child(2) {
    font-size: 16px;
  }
  .countdown img {
    width: 100%;
  }
  .link-form {
    height: 62.33px;
    margin-top: 15px;
    width: 100%;
    margin-left: 0;
    margin-bottom: 20px;
    max-width: 350px;
  }

  @media (max-width: 360px) {
    .content h1 {
      font-size: 2em;
    }
    .countdowntext div:first-child {
      font-size: 34px;
    }
    .countdowntext div:nth-child(2) {
      font-size: 16px;
    }
  }
}
