@font-face {
  font-family: "Pretendard-Regular";
  src: url("https://fastly.jsdelivr.net/gh/Project-Noonnu/noonfonts_2107@1.1/Pretendard-Regular.woff")
    format("woff");
  font-weight: 400;
  font-style: normal;
}

* {
  font-family: "Pretendard-Regular", sans-serif;
}

.header {
  z-index: 100;
  position: fixed;
  width: 100vw;
  height: 100px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #141414;
  color: #fff;
  transition: background-color 0.3s ease;
}

.logo {
  margin: 40px;
}

.logo img {
  cursor: pointer;
  width: 150px;
  margin-top: 15px;
  margin-bottom: 10px;
}

.menu {
  display: flex;
  gap: 30px;
  margin-right: 40px;
  flex-direction: row;
}

/* .menu a {
  color: #fff;
  text-decoration: none;
  font-size: 18px;
} */
.menu span,
.span {
  color: #fff;
  text-decoration: none;
  font-size: 18px;
  cursor: pointer;
  font-weight: 600;
}

.menu h1 {
  display: none;
}

.menu_open {
  display: none;
  cursor: pointer;
}

.menu-no-transition {
  transition: none !important;
}

@media (max-width: 768px) {
  .menu_open {
    display: block;
    margin-right: 40px;
    font-size: 16px;
    font-weight: 600;
  }
  .header {
    background: #141414;
  }

  .header.menu-open {
    /* animation: backgroundTransition 0.3s forwards; */
    background: #141414;
  }

  /* @keyframes backgroundTransition {
    0% {
      background: #141414;
    }
    100% {
    background: #1D26FF;
  }
} */

  .menu {
    overflow: hidden;
    height: 0px;
    position: fixed;
    top: 100px;
    background: #141414;
    margin-left: 0;
    width: 100vw;
    flex-direction: column;
    gap: 10px;
    transition: 0.3s all;
  }
  .menu_opened {
    background: #141414;
    height: 80vh;
  }

  .menu h1 {
    display: block;
    margin-left: 40px;
    font-size: 48px;
    margin-bottom: 10px;
  }

  .menu span,
  .span {
    border-bottom: 2px solid #fff;
    padding: 20px;
    padding-left: 40px;
    font-size: 24px;
    font-weight: 600;
  }

  .logo img {
    width: 120px;
  }
}

@media (max-width: 400px) {
  .menu h1 {
    font-size: 24px;
  }

  .menu span,
  .span {
    font-size: 20px;
  }
}
