/* *,
*::before,
*::after {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  list-style-type: none;
} */

.notice_page {
    background-color: #1D26FF;
    padding-top: 100px;
}
.notice_page h1 {
    margin-left: 40px;
    color: #fff;
}
.swiper_container {
  height: fit-content;
  padding: 2rem 10px;
  position: relative;
}

.swiper-slide {
    width: fit-content;
    height: fit-content;
    margin-bottom: 26px;
  /* height: 42rem; */
  position: relative;
}

@media (max-width: 1345px) {
  /* .swiper_container {
    height: 80vh;
  } */
  .swiper-slide img {
    width: 80vw !important;
  }
}
/* @media (max-width: 1095px) {
  .swiper_container {
    height: 37rem;
  }
}
@media (max-width: 850px) {
  .swiper_container {
    height: 30rem;
  }
}
@media (max-width: 400px) {
  .swiper_container {
    height: 10rem;
  }
} */

.swiper-slide img {
  width: 80rem;
  border-radius: 10px;
  object-fit: cover;
  box-shadow: 1px 1px 3px rgba(0, 0, 0, 0.5);
}

.swiper-slide-shadow-left,
.swiper-slide-shadow-right {
  display: none;
}

.slider-controler {
  position: relative;
  bottom: 2rem;
  display: flex;
  align-items: center;
  justify-content: center;
}

.slider-controler .swiper-button-next {
  left: 58% !important;
  transform: translateX(-58%) !important;
}

@media (max-width: 990px) {
  .slider-controler .swiper-button-prev {
    left: 30% !important;
    transform: translateX(-30%) !important;
  }
  .slider-controler .swiper-button-next {
    left: 70% !important;
    transform: translateX(-70%) !important;
  }
}

@media (max-width: 450px) {
  .slider-arrow {
    width: 2rem !important;
    height: 2rem !important;
  }
  .slider-arrow img{
    width: 1.8rem !important;
    height: 1.8rem !important;
  }
  .slider-controler .swiper-button-prev {
    left: 20% !important;
    transform: translateX(-20%) !important;
  }
  .slider-controler .swiper-button-next {
    left: 80% !important;
    transform: translateX(-80%) !important;
  }
}

@media (max-width: 400px) {
  .notice_page h1 {
    font-size: 24px;
  }
}
.slider-controler .slider-arrow {
  background: #000000;
  width: 3.5rem;
  height: 3.5rem;
  border-radius: 50%;
  left: 42%;
  transform: translateX(-42%);
  /* filter: drop-shadow(0px 8px 24px rgba(18, 28, 53, 0.1)); */
}

.slider-controler .slider-arrow::after {
  content: '';
}
