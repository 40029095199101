@font-face {
  font-family: "Pretendard-Regular";
  src: url("https://fastly.jsdelivr.net/gh/Project-Noonnu/noonfonts_2107@1.1/Pretendard-Regular.woff")
    format("woff");
  font-weight: 400;
  font-style: normal;
}

* {
  font-family: "Pretendard-Regular", sans-serif;
  box-sizing: border-box;
}

body {
  margin: 0;
}

.container {
  background-color: #141414;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 0 20px;
  align-items: flex-start;
  padding-top: 100px;
}

form {
  display: flex;
  flex-direction: column;
  align-items: start;
  width: 80%;
  max-width: 600px;
  margin-left: 20px;
  margin-top: 20px;
}

.form-group input,
.form-group select,
.custom-file-upload {
  width: 100%;
  padding: 0.5em;
  border: 2px solid #4d4d4d;
  font-size: 1em;
  background-color: #141414;
  color: #fff;
  display: inline-block;
  text-align: left;
  cursor: pointer;
}

form input::placeholder,
form select::placeholder {
  color: #969696;
}

.custom-file-upload {
  width: 100%;
}

.custom-file-upload input[type="file"] {
  display: none;
}

.form-group select {
  background-image: url("../../assets/arrow.svg");
  background-repeat: no-repeat;
  background-position: right 0.75em center;
  background-size: 1em;
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
}

form input::placeholder,
form select::placeholder {
  color: #d9d9d9;
}

span .span-portfolio {
  color: #d9d9d9;
  font-weight: lighter;
}

.form-group {
  display: flex;
  flex-direction: column;
  justify-content: left;
  margin-bottom: 1em;
  width: 100%;
}

.form-group-inline {
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin-bottom: 1em;
}

.form-group-inline .form-group {
  margin-bottom: 0;
  width: 48%;
}

.form-group-inline2 {
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin-bottom: 1em;
}

.form-group-inline2 .form-group {
  margin-bottom: 0;
  width: 76%;
}

.form-group-inline2 button {
  margin-bottom: 0;
  width: 20%;
  height: 25%;
}

form label {
  display: block;
  margin-bottom: 0.5em;
  font-weight: bold;
}

button {
  background-color: #fff;
  color: #141414;
  padding: 0.55em;
  border: solid;
  text-decoration: underline;
  font-size: 1em;
  cursor: pointer;
  margin-bottom: 0.5em;
  transition: background-color 0.3s ease;
}

button:hover {
  background-color: #333;
}

.container h1 {
  display: block;
  margin-left: 20px;
  font-size: 3em;
  margin-block-start: 0em;
  margin-block-end: 0em;
  color: #fff;
}

form {
  text-align: left;
}

@media (max-width: 850px) {
  .form-group-inline2 {
    flex-direction: column;
    width: 100%;
    margin-bottom: 1em;
  }

  .form-group-inline2 .form-group {
    width: 100%;
    margin-bottom: 0.5em;
  }

  .form-group-inline2 button {
    width: auto;
    align-self: flex-end;
  }
}

@media (max-width: 767px) {
  .form-group-inline {
    flex-direction: column;
  }

  .form-group-inline .form-group {
    width: 100%;
  }

  .form-group-inline .form-group:first-child {
    margin-bottom: 20px;
  }

  .container {
    padding: 0 20px;
    padding-top: 100px;
  }
}

div .container p {
  color: #fff;
  font-weight: bold;
  text-decoration: underline;
  display: flex;
  flex-direction: column;
  margin: 20px;
  /* align-items: center;*/
}

.custom-file-upload {
  font-weight: 500;
  /* color: #969696; */
}
