.faq-container {
  width: 100%;
  padding-top: 100px;
  padding-bottom: 100px;
  background-color: #141414;
}

.faq-container h1 {
  color: #fff;
  text-align: left;
  margin-left: 40px;
  margin-bottom: 40px;
}

.faq-item {
  margin-bottom: 10px;
}

.faq-question {
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  padding: 10px;
  background: #141414;
  border: 1px solid #fff;
  font-size: 24px;
  font-weight: 500;
  margin-left: 40px;
  margin-right: 40px;
  color: #fff;
}

.faq-question:hover {
  background: #272727;
}

.faq-answer {
  padding: 10px;
  background: #fff;
  border: 1px solid #ddd;
  border-top: none;
  font-size: 18px;
  /* font-weight: 600; */
  margin-left: 40px;
  margin-right: 40px;
  color: #141414;
}

.question-icon {
  width: 30px;
  height: 30px;
}

.faq-question.open {
  background: #fff;
  color: #141414;
}

.faq-item.open .faq-question {
  background: #fff;
  color: #141414;
}

@media (max-width: 400px) {
  .faq-container h1 {
    font-size: 24px;
  }
  .faq-question {
    font-size: 16px;
  }
  .faq-answer {
    font-size: 14px;
  }
  .question-icon {
    width: 20px;
    height: 20px;
  }
}
