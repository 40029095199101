.guestbook {
    background-color: #141414;
    color: #fff;
    padding: 20px;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    min-height: calc(100vh - 183.63px);
  }
  
  .a {
    display: flex;
    justify-content: space-between;
    width: 100%;
    margin-bottom: 20px;
    margin-top: 100px;
    padding: 20px;
  }
  
  .aa {
    font-size: 34px;
  }
  
  .ab {
    cursor: pointer;
    background-color: #fff;
    color: #141414;
    padding: 5px 10px;
    font-weight: 600;
    font-size: 24px;
    /* line-height: 39px; */
  }

  .A{
    margin: 30px;
    display: flex;
    width: 100%;
    /* align-items: flex-start; */
    /* justify-self: flex-start; */
    flex-direction: column;
    font-size: 18px;
    /* margin-bottom: 40px; */
    padding: 20px;
  }
  .A div {
    margin-top: 10px;
  }
  
  .b {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
    gap: 20px;
    width: 100%;
    height: 100%;
    padding: 20px;
    margin: 30px;
  }
  
  .ba {
    background-color: #141414;
    padding: 20px;
    border: 1px solid #fff;
    box-sizing: border-box;
  }
  
  .baa {
    margin-bottom: 30px;
  }
  
  .bab, .bac {
    display: inline-block;
    width: 48%;
    margin: 5px 1%;
  }
  
  .bab {
    text-align: left;
  }
  
  .bac {
    text-align: right;
  }
  
  @media (max-width: 400px) {
    .ba {
      width: 100%;
    }
  }
  
  .x {
    width: 500px;
    height: 310px;
    background: #141414;
    position: fixed;
    top: calc(50% - 155px);
    left: calc(50% - 250px);
    padding: 10px;
    border: 3px solid #fff;
    z-index: 1000;
  }
  
  .x h2 {
    margin-bottom: 10px;
    color: white;
  }
  
  .x textarea {
    width: 100%;
    resize: none;
    height: 180px;
    margin-bottom: 10px;
    padding: 5px;
    outline: none;
    background: #000;
    border: 3px solid #fff;
    font-size: 24px;
    color: #fff;
  }
  
  .x input {
    width: 140px;
    height: 50px;
    padding: 5px;
    outline: none;
    font-size: 20px;
    background: #141414;
    border: 3px solid #fff;
    color: #fff;
  }
  
  .bellow_text {
    display: flex;
    width: 100%;
    justify-content: space-between;
    flex-direction: row;
  }
  
  .bellow_text button {
    font-size: 22px;
    background: #fff;
    border: none;
    color: #141414;
    text-decoration: none;
    font-weight: 600;
  }
  
  @media (max-width: 554px) {
    .x {
        width: 320px;
        height: 300px;
        padding-bottom: 10px;
        background: #141414;
        position: fixed;
        top: calc(50% - 150px);
        left: calc(50% - 160px);
        padding: 10px;
        border: 3px solid #fff;
        z-index: 1000;
    }
    
    .x h2 {
        margin-bottom: 10px;
        color: white;
    }
    
    .x textarea {
        width: 100%;
        resize: none;
        height: 180px;
        margin-bottom: 10px;
        padding: 5px;
        outline: none;
        background: #000;
        border: 2px solid #fff;
        font-size: 16px;
        color: #fff;
    }
    
    .x input {
        width: 70px;
        height: 40px;
        padding: 5px;
        outline: none;
        font-size: 14px;
        background: #141414;
        border: 2px solid #fff;
        color: #fff;
    }
    
    .bellow_text {
        display: flex;
        width: 100%;
        justify-content: space-between;
        flex-direction: row;
    }
    
      .bellow_text button {
        font-size: 16px;
        height: 40px;
        background: #fff;
        border: none;
        color: #141414;
        text-decoration: none;
        font-weight: 600;
    }
  }
  @media (max-width: 394px) {
      .x {
          width: 290px;
          left: calc(50% - 145px);
        }
        .x input {
            width: 60px;
            height: 36px;
            font-size: 10px;
        }
        .bellow_text button {
            height: 36px;
            font-size: 10px;
            padding: 11.8px;
        }
        .b {
            margin: 0;
            padding: 3px;
        }
        .guestbook {
            padding: 3px;
        }
    }