.footer-component {
    background-color: #141414; 
    color: #fff; 
    padding: 20px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }
  
  .footer-top {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    width: 100%;
  }
  
  .footer-top span {
    margin-right: 10px;
  }
  
  .footer-logo {
    display: flex;
    align-items: center;
  }
  
  .footer-logo img {
    width: 15px;
    height: auto;
  }
  
  .footer-bottom {
    margin-top: 10px;
  }
  
  .footer-bottom a {
    color: #fff;
    text-decoration: underline;
  }
  
  @media (max-width: 767px) {
    .full-text {
      display: none;
    }
  }
  
  @media (min-width: 768px) {
    .short-text {
      display: none;
    }
  }
  