* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  background: #f2f2f2;
}

.logos {
  overflow: hidden;
  background: #141414;
  white-space: nowrap;
  position: relative;
  padding-top: 40px;
}

.logos:before,
.logos:after {
  position: absolute;
  top: 0;
  width: 250px;
  height: 100%;
  content: "";
  z-index: 2;
}

.logos:before {
  left: 0;
}

.logos:after {
  right: 0;
}

@keyframes slide {
  0% {
    transform: translateX(-10%);
  }
  100% {
    transform: translateX(0%);
  }
}

.logos-slide {
  display: inline-block;
  animation: slide 10s infinite linear;
}

.logos-slide img {
  height: 50px;
}

