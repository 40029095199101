@font-face {
  font-family: "Pretendard-Regular";
  src: url("https://fastly.jsdelivr.net/gh/Project-Noonnu/noonfonts_2107@1.1/Pretendard-Regular.woff")
    format("woff");
  font-weight: 400;
  font-style: normal;
}

* {
  font-family: "Pretendard-Regular", sans-serif;
}

h1 {
  font-size: 36px;
  margin-top: 20px;
  font-weight: 700;
}

::selection {
  color: #000;
  background-color: rgba(212, 255, 32, 1);
}
