@font-face {
  font-family: "Pretendard-Regular";
  src: url("https://fastly.jsdelivr.net/gh/Project-Noonnu/noonfonts_2107@1.1/Pretendard-Regular.woff")
    format("woff");
  font-weight: 400;
  font-style: normal;
}

* {
  font-family: "Pretendard-Regular", sans-serif;
}

.header2 {
  z-index: 100;
  position: fixed;
  width: 100vw;
  height: 100px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #141414;
  color: #fff;
  transition: background-color 0.3s ease;
}

.header2.menu-open {
  animation: backgroundTransition 0.3s forwards;
}

.logo2 {
  margin: 40px;
  cursor: pointer;
}

.logo2 img {
  width: 150px;
  margin-top: 15px;
  margin-bottom: 10px;
}

.menu2 {
  display: flex;
  gap: 30px;
  margin-right: 30px;
}

.menu2 .checklink {
  color: #fff;
  text-decoration: none;
  font-size: 18px;
  font-weight: 600;
}

.menu2 .menu-hack,
.menu-faq,
.menu-sche {
  color: #141414;
  text-decoration: none;
  font-size: 18px;
}

.menu2 h1 {
  display: none;
}

.menu2 div {
  cursor: pointer;
}

.menu_open2 {
  display: none;
}

.menu-no-transition {
  transition: none !important;
}

@media (max-width: 768px) {
  /* .menu_open2 {
    display: block;
    margin-right: 20px;
    font-size: 18px;
  }

  .menu2 {
    overflow: hidden;
    height: 0px;
    position: fixed;
    top: 100px;
    background: #1d26ff;
    margin-left: 0;
    width: 100vw;
    flex-direction: column;
    gap: 10px;
    transition: 0.3s height;
  }

  .menu2 h1 {
    display: block;
    margin-left: 15px;
    font-size: 50px;
    margin-bottom: 10px;
    color: #fff;
  } */

  .menu2 .checklink {
    padding: 20px;
    font-size: 16px;
    font-weight: 600;
    color: #fff;
  }
/*
  .menu2 .menu-hack,
  .menu-faq,
  .menu-sche {
    font-size: 25px;
    font-weight: 600;
    border-bottom: 2px solid #fff;
    padding: 20px;
    color: #fff;
  }

  .logo2 img {
    width: 120px;
  } */
}

/* @keyframes backgroundTransitionReverse {
  0% {
    background: #1d26ff;
  }
  100% {
    background: #d4ff20;
  }
}

.header2.menu-close {
  animation: backgroundTransitionReverse 0.3s forwards;
} */

@media (max-width: 768px) {
  .logo2 img {
      width: 120px;
  }
}