.form-done-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  flex-direction: column;
  background-color: #141414;
}

.form-done-container h2 {
  font-size: 3em;
  color: #fff;
  margin-bottom: 10px;
}

.form-done-container div {
  font-size: 1.2em;
  margin-bottom: 10px;
  color: #fff;
}
