.agree-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  flex-direction: column;
  background-color: #141414;
  padding: 30px;
  color: #fff;
}

.agree-container h2 {
  font-size: 3em;
  margin-bottom: 50px;
}

.agree-box {
  width: 70%;
  border: 2px solid #fff;
  padding: 15px;
  margin-bottom: 20px;
  max-height: 265px; 
  overflow-y: auto; 
}

.agree-box::-webkit-scrollbar {
  width: 6px;
}
.agree-box::-webkit-scrollbar-thumb {
  background-color: #999999;
  border-radius: 3px;
}

.agree-box div {
  font-size: 1.2em;
  margin-bottom: 30px;
}

.agree {
  width: 70%;
  display: flex;
  justify-content: start;
  align-items: center; 
}

.agree_check {
  display: none;
}

.custom-checkbox {
  display: inline-block;
  width: 30px; 
  height: 30px; 
  border: 2px solid #fff;
  /* border-radius: 5px;  */
  position: relative;
  cursor: pointer;
}

.custom-checkbox:before {
  content: "";
  position: absolute;
  top: 5%;
  left: 55%;
  width: 16px;
  height: 8px; 
  border: solid #fff;
  border-width: 0 0 2px 2px; 
  transform: rotate(-45deg) translate(-50%, -50%);
  opacity: 0;
  transition: opacity 0.2s ease;
}

.agree_check:checked + .custom-checkbox:before {
  opacity: 1;
}

label[for="agree"] {
  cursor: pointer;
  margin-left: 10px;
  color: #fff;
}

.agree_to_form {
  margin-top: 80px;
  text-decoration: none;
  width: 100px;
  height: 60px;
  font-size: 22px;
}


@media (max-width: 1090px) {
  .agree-box {
    max-height: 300px; 
  }
}

@media (max-width: 1000px) {
  .agree-box {
    max-height: 350px; 
  }
}

@media (max-width: 700px) {
  .agree-box {
    max-height: 400px; 
  }
  .agree-container h2 {
    padding-top: 100px;
  }
}

@media (max-width: 576px) {
  .agree-box {
    max-height: 500px; 
  }
  .agree-container h2 {
    font-size: 36px;
  }
  .agree-box div {
    font-size: 1em;
  }
  .agree_to_form {
    margin-top: 80px;
    text-decoration: none;
    width: 80px;
    height: 50px;
    font-size: 18px;
  }
}

@media (max-width: 478px) {
  .agree-box {
    max-height: none; 
    height: auto; 
  }

  .agree-container h2 {
    font-size: 30px;
  }
  .agree-box div {
    font-size: 0.8em;
  }

  .custom-checkbox {
    width: 24px; 
    height: 24px; 
  }

  .custom-checkbox:before {
    width: 12px; 
    height: 6px; 
    border-width: 0 0 3px 3px; 
  }
  .agree_to_form {
    margin-top: 60px;
    text-decoration: none;
    width: 60px;
    height: 40px;
    font-size: 16px;
  }
}
