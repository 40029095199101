.introduction {
  background: #141414;
  display: flex;
  padding: 40px;
  color: white;
  padding-top: 100px;
  padding-bottom: 100px;
}

.intro-text {
  width: 55%;
  padding-right: 50px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  font-size: 24px;
  font-weight: 500;
  line-height: 1.5;
}

.intro-text h1 {
  margin: 0;
  padding-bottom: 20px;
}

.introduction img {
  width: 45%;
}

@media (max-width: 690px) {
  .introduction {
    flex-direction: column-reverse;
    align-items: center;
    text-align: center; 
  }
  .intro-text {
    width: 100%;
    margin-top: 10px;
    padding-right: 0;
  }
  .introduction img {
    width: 100%;
    margin-bottom: 40px;
  }
}

@media (max-width: 400px) {
  .intro-text h1 {
    font-size: 24px;
  }
  .intro-text p {
    font-size: 16px;
  }
}
