.schedule-container {
  width: 100%;
  padding-top: 100px;
  padding-bottom: 100px;
  background-color: #fff;
}

.schedule-container h1 {
  color: #141414;
  text-align: left;
  margin-left: 40px;
  margin-bottom: 40px;
}

.schedule-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
  padding: 10px;
  background: #fff;
  border: 1px solid #141414;
  font-size: 24px;
  /* font-weight: 600; */
  margin-left: 40px;
  margin-right: 40px;
  color: #141414;
}

.schedule-date {
  width: auto;
  font-weight: 500;
}

.schedule-event {
  width: auto;
  font-weight: 600;
}

@media (max-width: 400px) {
  .schedule-container h1 {
    font-size: 24px;
  }
  .schedule-item div {
    font-size: 16px;
  }
}
